<mat-drawer-container autosize [ngSwitch]="chatService.chatWindow">
  <app-header-mobile *ngIf="chatService.isMobile"></app-header-mobile>

  <mat-card class="mr-16">
    <div *ngSwitchCase="'empty'" class="chat-window-container">
      <mat-card-header>
        <div class="header">
        </div>
      </mat-card-header>
      <mat-card-content class="empty-chat-container" *ngSwitchCase="'empty'">
        <span class="text-align-center">Please choose a channel or direct message.</span>
      </mat-card-content>
      <mat-card-content class="textfield-content">
        <div class="text-field-container">
          <textarea disabled placeholder="Choose channel or direct Message or start a new conversation"></textarea>
          <div class="message-buttons">
            <div class="attachments">
              <div class="border-right">
                <button disabled mat-icon-button>
                  <img src="assets/img/icons/add.svg" alt="" />
                </button>
              </div>
              <button disabled (click)="this.emojiService.openEmojiPicker('', 'channels')" mat-icon-button>
                <img src="assets/img/icons/smiley.svg" alt="" />
              </button>
              <button disabled mat-icon-button>
                <img src="assets/img/icons/@.svg" alt="" />
              </button>
            </div>
            <button class="send-button" mat-icon-button disabled>
              <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2.5 19.2812C2.08333 19.4479 1.6875 19.4112 1.3125 19.1712C0.9375 18.9321 0.75 18.5833 0.75 18.125V13.4687C0.75 13.1771 0.833333 12.9167 1 12.6875C1.16667 12.4583 1.39583 12.3125 1.6875 12.25L10.75 9.99999L1.6875 7.74999C1.39583 7.68749 1.16667 7.54166 1 7.31249C0.833333 7.08333 0.75 6.82291 0.75 6.53124V1.87499C0.75 1.41666 0.9375 1.06749 1.3125 0.827493C1.6875 0.588327 2.08333 0.552076 2.5 0.718743L21.75 8.84374C22.2708 9.07291 22.5312 9.45833 22.5312 9.99999C22.5312 10.5417 22.2708 10.9271 21.75 11.1562L2.5 19.2812Z"
                  fill="#444DF2" />
              </svg>
            </button>
          </div>
        </div>
      </mat-card-content>
    </div>

    <div *ngSwitchCase="'newMessage'" class="h100">
      <app-new-message></app-new-message>
    </div>

    <div *ngSwitchCase="'channel'" class="chat-window-container">
      <mat-card-header *ngSwitchCase="'channel'">
        <div class="header">
          <button (click)="openEditChannelDialog()" mat-button class="br-32 channel-button">
            <div class="chat-title">
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_19885_8638" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24"
                  height="25">
                  <rect y="0.5" width="24" height="24" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_19885_8638)">
                  <path
                    d="M7.24995 20.5C6.93328 20.5 6.67495 20.375 6.47495 20.125C6.27495 19.875 6.21662 19.6 6.29995 19.3L6.99995 16.5H4.77495C4.44162 16.5 4.17495 16.371 3.97495 16.113C3.77495 15.8543 3.71661 15.5667 3.79995 15.25C3.84995 15.0167 3.96662 14.8333 4.14995 14.7C4.33328 14.5667 4.54162 14.5 4.77495 14.5H7.49995L8.49995 10.5H5.77495C5.44161 10.5 5.17495 10.371 4.97495 10.113C4.77495 9.85433 4.71662 9.56667 4.79995 9.25C4.84995 9.01667 4.96662 8.83333 5.14995 8.7C5.33328 8.56667 5.54162 8.5 5.77495 8.5H8.99995L9.82495 5.225C9.87495 5.00833 9.98328 4.83333 10.1499 4.7C10.3166 4.56667 10.5166 4.5 10.7499 4.5C11.0666 4.5 11.3249 4.625 11.5249 4.875C11.7249 5.125 11.7833 5.4 11.6999 5.7L10.9999 8.5H14.9999L15.8249 5.225C15.8749 5.00833 15.9833 4.83333 16.1499 4.7C16.3166 4.56667 16.5166 4.5 16.7499 4.5C17.0666 4.5 17.3249 4.625 17.5249 4.875C17.7249 5.125 17.7833 5.4 17.6999 5.7L16.9999 8.5H19.2249C19.5583 8.5 19.8249 8.629 20.0249 8.887C20.2249 9.14567 20.2833 9.43333 20.1999 9.75C20.15 9.98333 20.0333 10.1667 19.8499 10.3C19.6666 10.4333 19.4583 10.5 19.2249 10.5H16.4999L15.4999 14.5H18.2249C18.5583 14.5 18.8249 14.629 19.0249 14.887C19.2249 15.1457 19.2833 15.4333 19.1999 15.75C19.1499 15.9833 19.0333 16.1667 18.8499 16.3C18.6666 16.4333 18.4583 16.5 18.2249 16.5H14.9999L14.1749 19.775C14.1249 19.9917 14.0166 20.1667 13.8499 20.3C13.6833 20.4333 13.4833 20.5 13.2499 20.5C12.9333 20.5 12.6749 20.375 12.4749 20.125C12.2749 19.875 12.2166 19.6 12.2999 19.3L12.9999 16.5H8.99995L8.17495 19.775C8.12495 19.9917 8.01661 20.1667 7.84995 20.3C7.68328 20.4333 7.48328 20.5 7.24995 20.5ZM9.49995 14.5H13.4999L14.4999 10.5H10.4999L9.49995 14.5Z"
                    fill="black" />
                </g>
              </svg>
              <h2 class="mr-16">{{ currentChannel?.name}}</h2>
              <mat-icon>expand_more</mat-icon>
            </div>
          </button>

          <div class="group-members-container" *ngIf="!chatService.isMobile">
            <button mat-button *ngIf="screenSizeBelow1010()" class="group-members-button" (click)="openMemberDialog()">
              <div class="group-members-container">
                <div class="group-members">
                  <div class="d-flex" *ngFor="let member of firestoreService.firstThreeItems">
                    <img src="{{member.picture}}" />
                  </div>
                </div>
                <h3 *ngIf="firestoreService.allChannelMembers.length > 3" class="member-count"> +
                  {{firestoreService.allChannelMembers.length -3}}</h3>
              </div>
            </button>
            <button mat-icon-button class="add-user" (click)="openDialog()">
              <img src="assets/img/icons/add-person.svg" alt="" />
            </button>
          </div>
          <button *ngIf="chatService.isMobile" mat-icon-button class="add-user" (click)="openDialog()">
            <img src="assets/img/icons/add-person.svg" alt="" />
          </button>
        </div>
      </mat-card-header>

      <div class="first-chat-box" [ngSwitch]="firestoreService.messageIsExistingInChannel">
        <mat-card-content #messageContainer class="p-0 main-chat-container" #scrollContainer
          [scrollTop]="scrollPosition" *ngSwitchCase="true">
          <ng-container *ngFor="let organizedMessage of firestoreService.organizedChannelMessages.slice()">
            <section>
              <div class="date-container">
                <div class="separator"></div>
                <div class="time-box">{{ isToday(organizedMessage.date) ? 'Today' : organizedMessage.date }}</div>
                <div class="separator"></div>
              </div>

              <div (click)="message.messageSelected = false" class="message-row-container"
                [ngClass]="{'my-messages': message.creatorId == currentUser.id}"
                *ngFor="let message of organizedMessage.messages">
                <div class="message-box">
                  <div class="creator-picture-container">
                    <img src="{{message.profilePic}}" alt="" />
                  </div>

                  <div class="message-container">
                    <div class="message-data">
                      <span class="name">{{ message.creator }}</span>
                      <span class="date">{{ message.time }}</span>
                    </div>

                    <div [ngSwitch]="edit && message.creatorId == currentUser.id && editingMessage === message.id"
                      class="d-flex-end">
                      <div
                        [ngClass]="{'my-message-content': message.creatorId == currentUser.id, 'message-content': message.creatorId != currentUser.id,'display-none': message.content == '' && message.mentions.length == 0,}"
                        *ngSwitchCase="false">
                        <a (click)="openProfileDialog(messagemention['id'])"
                          *ngFor="let messagemention of message.mentions"> @{{messagemention['name']}}</a>
                        <span class="word-break">{{message.content}}</span>
                      </div>
                      <div class="d-flex">
                        <div *ngFor="let messagefile of message.files">
                          <img style="max-height: 100px; margin-top: 10px;"
                            *ngIf="this.authService.isImage(messagefile)" src="{{messagefile}}" alt="Angehängtes Bild">
                          <div class="pdf" *ngIf="this.authService.isPDF(messagefile)">
                            <img src="assets/img/icons/pdf.svg" alt="">
                            <a href="{{messagefile}}" target="_blank">Show PDF</a>
                          </div>
                        </div>
                      </div>

                      <div *ngIf="message.creatorId == currentUser.id && editingMessage === message.id">
                        <div *ngSwitchCase="true" class="text-field-container j-flex-end">
                          <textarea (keyup.enter)="updateMessageContent(message)" #editor cols="30"
                            rows="10">{{message.content}}</textarea>
                          <button class="send-button" mat-icon-button (click)="updateMessageContent(message)"
                            [disabled]="editor.value.length < 1">
                            <svg width="23" height="20" viewBox="0 0 23 20" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M2.5 19.2812C2.08333 19.4479 1.6875 19.4112 1.3125 19.1712C0.9375 18.9321 0.75 18.5833 0.75 18.125V13.4687C0.75 13.1771 0.833333 12.9167 1 12.6875C1.16667 12.4583 1.39583 12.3125 1.6875 12.25L10.75 9.99999L1.6875 7.74999C1.39583 7.68749 1.16667 7.54166 1 7.31249C0.833333 7.08333 0.75 6.82291 0.75 6.53124V1.87499C0.75 1.41666 0.9375 1.06749 1.3125 0.827493C1.6875 0.588327 2.08333 0.552076 2.5 0.718743L21.75 8.84374C22.2708 9.07291 22.5312 9.45833 22.5312 9.99999C22.5312 10.5417 22.2708 10.9271 21.75 11.1562L2.5 19.2812Z"
                                fill="#444DF2" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div class="reaction-container">
                      <div class="reaction-emoji-container">
                        <button class="reaction-emoji-button"
                          (click)="firestoreService.addReaction(emoji.key, message.id, currentChannel?.id, 'channels')"
                          *ngFor="let emoji of message.reactionCount | keyvalue; let i = index">
                          <div class="emoji-fit">
                            {{ emoji.key }}
                          </div>
                          <span>{{ emoji.value }}</span>
                        </button>
                      </div>

                      <div class="comment-section" (click)="openThread(message)">
                        <span *ngIf="message.threadCount == 0" class="comments color-purple2-text">Reply</span>
                        <span *ngIf="message.threadCount == 1" class="comments color-purple2-text"> 1 Comment</span>
                        <span *ngIf="message.threadCount >= 2" class="comments color-purple2-text">
                          {{message.threadCount}} Comments</span>
                        <span *ngIf="message.threadCount > 0" class="last-comment">Last Comment
                          {{message.lastThreadTime}}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="reaction" [ngClass]="{'own-message': message.creatorId == this.userService.currentUser.id}">
                  <button mat-icon-button
                    (click)="firestoreService.addReaction('✅',message.id, currentChannel?.id, 'channels')">
                    <img src="assets/img/emojis/checkGreen.svg" alt="">
                  </button>
                  <button mat-icon-button
                    (click)="firestoreService.addReaction('🙌', message.id, currentChannel?.id, 'channels')">
                    <img src="assets/img/emojis/hands.svg" alt="">
                  </button>
                  <button class="hostlistener-dont-trigger" (click)="this.emojiService.openEmojiPicker(message.id, 'channels')"
                    mat-icon-button>
                    <img src="assets/img/icons/add_reaction.svg" alt="">
                  </button>
                  <button (click)="openThread(message)" mat-icon-button>
                    <img src="assets/img/icons/comment.svg" alt="">
                  </button>

                  <button class="more-menu-button" mat-icon-button *ngIf="message.creatorId == currentUser.id"
                    (click)="firestoreService.toggleMoreMenu(message)">
                    <img src="assets/img/icons/more.svg" alt="">
                    <div class="more-menu" [class.visible]="message.messageSelected">
                      <span (click)="editMessage(message)">Edit message</span>
                      <span class="delete-message"
                        (click)="firestoreService.deleteMessageOfChat('channels', currentChannel?.id, message.id)">Delete
                        Message</span>
                    </div>
                  </button>
                </div>
              </div>
            </section>
          </ng-container>

        </mat-card-content>

        <mat-card-content *ngSwitchCase="false" class="empty-channel-message-container">
          <span># {{ currentChannel?.name }}</span>
          <span class="empty-room-description">This channel has been newly created. This is the beginning of the channel
            <b style="color: var(--purple3);"># {{ currentChannel?.name }}</b></span>
        </mat-card-content>

      </div>
      <div class="user-search-container-textfield main-responsive"
        *ngIf="this.userService.openUserContainerTextfield.value == true">
        <button (click)="getUserNameString(user)" class="user-container" *ngFor="let user of this.userService.users">
          <div class="profile-container">
            <img src="{{user.picture}}" alt="profile">
            this.userService.currentUser.picture
          </div>
          <span>{{user.name}}</span>
        </button>
      </div>

      <mat-card-content>
        <div class="textfield-content">
          <div class="text-field-container">
            <textarea [disabled]="!currentChannel" (keyup.enter)="sendMessage()" [(ngModel)]="message.content"
              placeholder="Message to {{currentChannel?.name}}" #mainChatTextarea></textarea>
            <mat-spinner *ngIf="firestoreService.showSpinner == true" class="mat-spinner --purple1"></mat-spinner>
            <div class="message-buttons">
              <div class="attachments">
                <div class="border-right">

                  <button class="mat-icon-button" type="submit"> <label class="custom-file-upload">
                      <input class="dataInput" (change)="onFileSelected($event)" type="file"
                        accept="image/jpeg, image/png, application/pdf">
                      <img style="z-index: 100;" src="assets/img/icons/add.svg" alt="" />
                    </label>
                  </button>
                </div>

                <button [disabled]="!currentChannel" (click)="this.emojiService.openEmojiPicker('', 'channels')" mat-icon-button>
                  <img src="assets/img/icons/smiley.svg" alt="" />
                </button>

                <button [disabled]="!currentChannel" (click)="this.userService.openUserContainerTextField()"
                  mat-icon-button>
                  <img src="assets/img/icons/@.svg" alt="" />
                </button>

                <ng-container *ngFor="let messagefile of this.message.files">
                  <img *ngIf="this.authService.isImage(messagefile) && this.showUploadedFile"
                    src="assets/img/icons/photo.svg" alt="Angehängtes Bild">
                  <img *ngIf="this.authService.isPDF(messagefile) && this.showUploadedFile"
                    src="assets/img/icons/pdf.svg" alt="">
                </ng-container>
              </div>
              <button class="send-button" mat-icon-button (click)="sendMessage()"
                [disabled]="!message.content && !showUploadedFile">
                <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M2.5 19.2812C2.08333 19.4479 1.6875 19.4112 1.3125 19.1712C0.9375 18.9321 0.75 18.5833 0.75 18.125V13.4687C0.75 13.1771 0.833333 12.9167 1 12.6875C1.16667 12.4583 1.39583 12.3125 1.6875 12.25L10.75 9.99999L1.6875 7.74999C1.39583 7.68749 1.16667 7.54166 1 7.31249C0.833333 7.08333 0.75 6.82291 0.75 6.53124V1.87499C0.75 1.41666 0.9375 1.06749 1.3125 0.827493C1.6875 0.588327 2.08333 0.552076 2.5 0.718743L21.75 8.84374C22.2708 9.07291 22.5312 9.45833 22.5312 9.99999C22.5312 10.5417 22.2708 10.9271 21.75 11.1562L2.5 19.2812Z"
                    fill="#444DF2" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </mat-card-content>
    </div>
    <div *ngSwitchCase="'direct'" style="height: 100%;">
      <app-direct-message-chat style="display: flex; flex-direction: column; height: 100%;"></app-direct-message-chat>
    </div>
  </mat-card>

  <mat-drawer style="padding-right: 16px;" #thread mode="side" [opened]="chatService.threadDrawerState$ | async"
    position="end">
    <app-thread style="width: 25%"></app-thread>
  </mat-drawer>
</mat-drawer-container>