<mat-card>
    <mat-card-content>
        <header>
            <h1>Add Members</h1>
            <button mat-icon-button (click)="dialogRef.close()">
                <mat-icon>close</mat-icon>
            </button>
        </header>

        <h3># {{currentChat?.name}}</h3>
        <div class="add-members-container">
            <input class="w100 input-members" type="text" placeholder="Enter name" [(ngModel)]="searchInput"
                (input)="firestoreService.filterAllUsers(searchInput)" (click)="this.addGroupInputFocused = true;">
            <div class="user-search-container" *ngIf="addGroupInputFocused" (click)="userSelected($event)">
                <div *ngIf="searchInput.trim() !== '' && firestoreService.filteredUsers.length !== 0">
                    <button #userContainer class="user-container"
                        *ngFor="let user of firestoreService.filteredUsers; let i = index"
                        (click)="selectUser(user, i)">
                        <div class="profile-container">
                            <img src="{{user.picture}}" alt="profile">
                            <div class="online-status"></div>
                        </div>
                        <span>{{user.name}}</span>
                    </button>
                </div>
                <span *ngIf="searchInput === '' || firestoreService.filteredUsers.length == 0">No matching
                    contact</span>
            </div>
            <div class="w100 selected-user-container">
                <div class="selected-user" *ngFor="let item of this.selectedUsers">
                    <img src="{{item.picture}}" alt="">
                    <span>{{item.name}}</span>
                    <button class="" mat-icon-button (click)="removeUser(item)">
                        <img src="assets/img/icons/close.svg">
                    </button>
                </div>
            </div>
        </div>
        <div class="button-position w100">
            <button mat-button class="main-button primary" (click)="addChannelMember()">
                Add
            </button>
        </div>
    </mat-card-content>
</mat-card>