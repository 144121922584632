<mat-spinner *ngIf="firestoreService.showSpinner == true" class="mat-spinner "></mat-spinner>
<div *ngIf="this.authService.signInSuccess == true" class="account-popup"><span>Login successfull</span></div>
<div *ngIf="showUserCreatedSuccess == true" class="account-popup"><span>Account created</span></div>
<div *ngIf="showEmailSent == true" class="account-popup"><span>E-mail sent</span></div>
<div *ngIf="!animationIsFinished" class="start-animation-container">
  <div class="animated-content-container">
    <div class="animation-content">
      <div class="start-animation-logo">
        <img src="assets\img\icons\logo.svg">
      </div>
      <span class="start-animation-span">DABubble</span>
    </div>
  </div>
</div>

<div [ngSwitch]="switch_expression" class="login-container">
  <div class="login-header">
    <img class="start-logo" src="assets\img\icons\logo-home.svg">
    <div *ngSwitchCase="'login'" class="top-span">
      <span class="padding-bottom">New at DABubble ?</span>
      <span (click)="changeSwitchCase('createAccount')" class="color-purple2-text cursor-pointer transform">Create
        Account</span>
    </div>
  </div>

  <div *ngSwitchCase="'login'" class="login bg-white">
    <div class="form-container">
      <h1 class="color-purple2-text padding-bottom">Login</h1>
      <p>We recommend using the email address that you use at work.</p>

      <form [formGroup]="login">
        <div class="login-fields">
          <div class="input-container">
            <img src="assets\img\icons\mail.svg">
            <input matInput placeholder="E-mail" [formControl]="loginemail" required>
            <mat-error *ngIf="loginemail.invalid && loginemail.touched">
              {{ getErrorMessageLogin() }}
            </mat-error>
          </div>

          <div class="input-container"><img src="assets\img\icons\lock.svg"><input type="password" matInput
              placeholder="Password" [formControl]="loginpassword" required>
            <mat-error *ngIf="loginpassword.invalid && loginpassword.touched">
              Wrong E-mail or password
            </mat-error>
            <mat-error>{{getErrorMessageNoUser()}}</mat-error>
          </div>
        </div>

        <span (click)="changeSwitchCase('resetPassword')"
          class="margin-top25 color-purple2-text cursor-pointer transform">Forgot password ?</span>
        <div class="or-container margin-top">
          <div class="bottom-line"></div><span class="or">OR</span>
          <div class="bottom-line"></div>
        </div>
        <div (click)="loginWithGoogle()" mat-button class="input-container  cursor-pointer google-button">
          <img src="assets\img\icons\google.png">
          <span class="color-purple2-text ">Sign in with Google</span>
        </div>
        <div class="submit-buttons margin-top">
          <button (click)="loginUser()" mat-button class="main-button primary mr-16" type="submit">Login</button>
          <button (click)="loginGuest()" mat-button class="main-button ">Guest login</button>
        </div>
      </form>
    </div>
  </div>

  <div *ngSwitchCase="'createAccount'" class="login bg-white">
    <div class="form-container">
      <img (click)="changeSwitchCase('login')" class="position-relative cursor-pointer transform arrow"
        src="assets\img\icons\arrow_back.svg">
      <h1 class="color-purple2-text padding-bottom"> Create account</h1>
      <p>Create your account with your name and e-mail adress.</p>

      <form [formGroup]="addUser" (ngSubmit)="createUser()">
        <div class="input-container margin-top25">
          <img src="assets\img\icons\person.svg">
          <input minlength="5" formControlName="newName" matInput placeholder="Name" required>
          <div class="placeholder"></div>
          <mat-error class="error" *ngIf="addUser.get('newName')?.hasError('minlength')">Your name need minimum 5
            letters</mat-error>
        </div>

        <div class="input-container margin-top25">
          <img src="assets\img\icons\mail.svg">
          <input formControlName="newEmail" matInput placeholder="E-mail" required>
          <div class="placeholder"></div>
          <mat-error class="error" *ngIf="addUser.get('newEmail')?.errors?.['pattern']">Please enter a valid E-Mail
            address</mat-error>
        </div>

        <div class="input-container margin-top25"><img src="assets\img\icons\lock.svg">
          <input type="password" formControlName="newPassword" matInput placeholder="Password" required>
          <div class="placeholder"></div>
          <mat-error class="error error-bottom" *ngIf="addUser.get('newPassword')?.hasError('minlength')">
            You need a password with minimum 8 letters
          </mat-error>
          <mat-error class="error" *ngIf="this.userAlreadyInUse">This user already exists</mat-error>
        </div>

        <span class="margin-top gap checkbox-bottom cursor-pointer align-center">
          <input [formControl]="disableSelect" class="cursor-pointer" type="checkbox">I accept the<a routerLink="/privacy" class="color-purple2-text transform">privacy policy</a>
        </span>
        <div class="submit-buttons justify-content-end">
          <button mat-button class="align-self main-button primary" type="submit"
            [disabled]="!disableSelect.value || !addUser.valid ">Next</button>
        </div>
      </form>
    </div>
  </div>

  <div *ngSwitchCase="'avatar'" class="login bg-white margin-top30">
    <div class="form-container">
      <img (click)="changeSwitchCase('createAccount')" class="position-relative cursor-pointer transform arrow"
        src="assets\img\icons\arrow_back.svg">
      <h1 class="color-purple2-text padding-bottom">Choose your Avatar</h1>
      <div class="big-profile-pic"><img class="userPic" src="{{this.picSrc}}"></div>
      <h2>{{newUser.name}}</h2>
      <form [formGroup]="addUser">
        <div class="choose-profile-pic margin-top"><span class="font-size20 w100">Choose from list</span>
          <div class="picture-container w100">
            <div *ngFor="let pic of profileImages ; index as i;"> <img
                [ngClass]="{'pic-border': selectedImageIndex === i}" class="transform cursor-pointer"
                (click)="changePicSrc(pic,i)" src="{{pic}}"></div>
          </div>
        </div>
        <div class="upload-picture margin-top align-center">
          <span class="font-size20"></span>
          <button mat-button class="main-button" type="submit">
            <label class="custom-file-upload">
              <input class="dataInput" (change)="onFileSelected($event)" type="file" accept="image/jpeg, image/png">
              Upload file
            </label>
          </button>
        </div>

        <div class="submit-buttons margin-top justify-content-end">

          <button mat-button (click)="uploadUser()" class="align-self main-button primary" type="submit">Next</button>
        </div>
      </form>
    </div>
  </div>

  <div *ngSwitchCase="'resetPassword'" class="login bg-white">
    <div class="form-container">
      <img (click)="changeSwitchCase('login')" class="position-relative cursor-pointer transform arrow"
        src="assets\img\icons\arrow_back.svg">
      <h1 class="color-purple2-text padding-bottom">Reset password</h1>
      <p style="font-size:20px;">Please enter your E-mail adress.</p>
      <form class="align-center" [formGroup]="getEmailForNewPassword">
        <div class="input-container margin-top">
          <img src="assets\img\icons\mail.svg">
          <input matInput placeholder="E-mail" formControlName="emailForReset" required>
          <mat-error *ngIf="emailForReset.invalid && emailForReset.touched">
            {{ getErrorMessageResetEmail() }}
          </mat-error>
        </div>
        <p *ngIf="!this.userService.resetEmailFound" class="margin-top25 grey-span-resetpw">Receive an e-mail to reset
          your password</p>
        <p *ngIf="this.userService.resetEmailFound" class="margin-top25 grey-span-resetpw">An e-mail to reset your
          password was sent !</p>
        <div class="submit-buttons margin-top justify-content-end">
          <button mat-button (click)="sendResetEmail()" [disabled]="!this.emailForReset.valid"
            class="main-button primary" type="submit">Send Email</button>
        </div>
      </form>
    </div>
  </div>

  <div *ngSwitchCase="'login'" class="responsive-create-account"><span>New at DABubble ?</span>
    <button (click)="changeSwitchCase('createAccount')" mat-button class="main-button ">Create Account</button>
  </div>
  <div class="impressum-span">
    <span routerLink="/imprint">Legal Notice</span>
    <span routerLink="/privacy">Privacy Policy</span>
  </div>
</div>