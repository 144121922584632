<mat-card>
    <mat-card-header>
        <header class="w100">
            <h1>Members</h1>
            <button *ngIf="!chatService.isMobile" mat-icon-button (click)="dialogRef?.close()">
                <mat-icon>close</mat-icon>
            </button>
        </header>
    </mat-card-header>
    <mat-card-content class="member-view">
        <div *ngFor="let member of allChannelMembers">
            <div class="member">
                <img class="mr-16" src="{{member.picture}}" alt="">
                <div [ngClass]="{'offline-status': member.online === false}" class="online-status"></div>
                <h3>{{member.name}}</h3>
            </div>
        </div>
    </mat-card-content>
    <mat-card-content class="add-member-container">
        <button mat-button class="add-member" (click)="openDialog()">
            <div class="display-flex">
                <img class="mr-16" src="assets/img/icons/add-person.svg" alt="" />
                <h3>Add Members</h3>
            </div>
        </button>
    </mat-card-content>
</mat-card>