<div class="home-container" *ngIf="!chatService.isMobile">
  <header>
    <div class="d-flex">
      <img class="logo" src="assets/img/icons/logo-home.svg" alt="home" />
    </div>

    <div class="add-members-container">
      <input #search class="w100 input-members search" type="text" placeholder="Search" [(ngModel)]="searchInput"
        (input)="filterEverything()" (click)="this.homeInputFocused = true;">
      <div class="user-search-container home-search" *ngIf="homeInputFocused">
        <div
          *ngIf="this.searchInput.trim() !== '' && !(filteredChannelMessages.length === 0 && firestoreService.filteredUsers.length === 0 && filteredDirectMessages.length === 0)"
          class="search-results">

          <h1>Users:</h1>
          <div *ngIf="firestoreService.filteredUsers.length !== 0">
            <button class="user-container" *ngFor="let user of firestoreService.filteredUsers"
              (click)="selectUser(user)">
              <div (click)="selectUser(user)" class="profile-container">
                <img src="{{user.picture}}" alt="profile">
                <div [ngClass]="{'offline-status': user.online == false}" class="online-status"></div>
              </div>
              <span>{{user.name}}</span>
            </button>
          </div>
          <span *ngIf="firestoreService.filteredUsers.length === 0">No matching results</span>

          <h1>Channel messages:</h1>
          <div *ngIf="filteredChannelMessages.length !== 0 ">
            <div class="found-msg-container" *ngFor="let message of filteredChannelMessages"
              (click)="selectChannel(message)">
              <img src="{{message.profilePic}}" alt="" />
              <div class="message-box">
                <div class="message-data">
                  <span class="name">{{ message.creator }}</span>
                  <span class="date">{{ message.time }}</span>
                </div>
                <span class="found-dm-content">{{message.content}}</span>
                <span class="found-msg-channel">in #{{message.channel}}</span>
              </div>
            </div>
          </div>
          <span *ngIf="filteredChannelMessages.length === 0">No matching results</span>

          <h1>Direct Messages:</h1>
          <div *ngIf="filteredDirectMessages.length !== 0">
            <div class="found-msg-container" *ngFor="let message of filteredDirectMessages"
              (click)="selectDirectMessage(message)">
              <img src="{{message.profilePic}}" alt="" />
              <div class="message-box">
                <div class="message-data">
                  <span class="name">{{ message.creator }}</span>
                  <span class="date">{{ message.time }}</span>
                </div>
                <span class="found-dm-content">{{message.content}}</span>
              </div>
            </div>
          </div>
          <span *ngIf="filteredDirectMessages.length === 0">No matching results</span>
        </div>
        <span
          *ngIf="searchInput.trim() === '' || filteredChannelMessages.length === 0 && firestoreService.filteredUsers.length === 0 && filteredDirectMessages.length === 0">No
          matching results</span>
      </div>
    </div>

    <div class=" profile-container" [matMenuTriggerFor]="menu">
      <h1>{{this.userService.currentUser.name}}</h1>
      <div class="profile-picture">
        <img src="{{this.userService.currentUser.picture}}" alt="" />
        <div class="online-status"></div>
      </div>
      <button class="header-menu-btn" mat-icon-button>
        <mat-icon>expand_more</mat-icon>
      </button>
      <mat-menu #menu="matMenu" xPosition="before" class="br-32">
        <button (click)="openProfileDialog(currentUser.id)" class="menu" mat-menu-item>Profile</button>
        <button (click)="logOutUser()" mat-menu-item>Log Out</button>
      </mat-menu>
    </div>
  </header>

  <main>
    <mat-drawer-container autosize>
      <mat-drawer style="padding-left: 16px;" #workspace mode="side"
        [opened]="chatService.workspaceDrawerState$ | async">
        <app-workspace></app-workspace>
      </mat-drawer>
      <div class="main-chat-container">
        <app-main-chat></app-main-chat>
      </div>
    </mat-drawer-container>

    <button class="workspace-button" type="button" mat-button (click)="chatService.toggleWorkspace()">
      <span>Workspace-Menu</span>
      <mat-icon>expand_more</mat-icon>
    </button>
  </main>
</div>

<!-- mobile -->
<div class="home-container" *ngIf="chatService.isMobile">
  <app-header-mobile></app-header-mobile>

  <div class="w100 home-mobile">
    <div class="add-members-container">
      <input #search class="w100 input-members search" type="text" placeholder="Search" [(ngModel)]="searchInput"
        (input)="filterEverything()" (click)="this.homeInputFocused = true;">
      <div class="user-search-container" *ngIf="homeInputFocused">

        <div
          *ngIf="this.searchInput.trim() !== '' && !(filteredChannelMessages.length === 0 && firestoreService.filteredUsers.length === 0 && filteredChannelMessages.length === 0)"
          class="search-results">

          <h1>Users:</h1>
          <div *ngIf="firestoreService.filteredUsers.length !== 0">
            <button class="user-container" *ngFor="let user of firestoreService.filteredUsers"
              (click)="selectUser(user)">
              <div (click)="selectUser(user)" class="profile-container">
                <img src="{{user.picture}}" alt="profile">
                <div [ngClass]="{'offline-status': user.online === false}" class="online-status"></div>
              </div>
              <span>{{user.name}}</span>
            </button>
          </div>
          <span *ngIf="firestoreService.filteredUsers.length === 0">No matching results</span>

          <h1>Channel messages:</h1>
          <div *ngIf="filteredChannelMessages.length !== 0 ">
            <div class="found-msg-container" *ngFor="let message of filteredChannelMessages"
              (click)="selectChannel(message)">
              <img src="{{message.profilePic}}" alt="" />
              <div class="message-box">
                <div class="message-data">
                  <span class="name">{{ message.creator }}</span>
                  <span class="date">{{ message.time }}</span>
                </div>
                <span class="found-dm-content">{{message.content}}</span>
                <span class="found-msg-channel">in #{{message.channel}}</span>
              </div>
            </div>
          </div>
          <span *ngIf="filteredChannelMessages.length === 0">No matching results</span>

          <h1>Direct Messages:</h1>
          <div *ngIf="filteredDirectMessages.length !== 0">
            <div class="found-msg-container" *ngFor="let message of filteredDirectMessages"
              (click)="selectDirectMessage(message)">
              <img src="{{message.profilePic}}" alt="" />
              <div class="message-box">
                <div class="message-data">
                  <span class="name">{{ message.creator }}</span>
                  <span class="date">{{ message.time }}</span>
                </div>
                <span class="found-dm-content">{{message.content}}</span>
              </div>
            </div>
          </div>
          <span *ngIf="filteredDirectMessages.length === 0">No matching results</span>
        </div>
        <span
          *ngIf="searchInput.trim() === '' || filteredChannelMessages.length === 0 && firestoreService.filteredUsers.length === 0 && filteredChannelMessages.length === 0">No
          matching results</span>
      </div>
    </div>
    <app-workspace></app-workspace>
  </div>
</div>