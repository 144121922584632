<app-header-mobile *ngIf="chatService.isMobile"></app-header-mobile>
<mat-card>
  <mat-card-header>
    <header>
      <div class="header">
        <h2 class="title">Thread</h2>
      </div>
      <button mat-icon-button class="close-thread" (click)="onCloseClick()">
        <img class="close-icon" src="assets/img/icons/close.svg" alt="close">
      </button>
    </header>
  </mat-card-header>

  <!-- top message -->
  <mat-card-content #scrollContainer [scrollTop]="scrollPosition" class="p-0 main-chat-container">
    <section>
      <div class="message-row-container mt-thread">
        <div class="message-box">
          <div class="message-avatar-container">
            <img src="{{this.threadService.currentMessage.profilePic}}" alt="" />
          </div>
          <div class="message-container">
            <div class="message-data">
              <span class="name">{{this.threadService.currentMessage.creator}}</span>
              <span class="date">{{this.threadService.currentMessage.time}}</span>
            </div>

            <div class="message message-content">
              <span class="word-break">{{this.threadService.currentMessage.content}}</span>
              <a (click)="openProfileDialog(mention['id'])"
                *ngFor="let mention of threadService.currentMessage.mentions">
                @{{mention.name}}</a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="comments-container">
      <span *ngIf="firestoreService.allThreadMessages.length == 1"
        class="comments">{{firestoreService.allThreadMessages.length}}
        Comment</span>
      <span *ngIf="firestoreService.allThreadMessages.length >= 2"
        class="comments">{{firestoreService.allThreadMessages.length}}
        Comments</span>
      <div class="separator"></div>
    </div>

    <section>
      <div class="message-row-container" *ngFor="let thread of firestoreService.allThreadMessages;"
        [ngClass]="{'my-messages': thread.creatorId == currentUser.id}">
        <div class="message-box">
          <div class="message-avatar-container">
            <img src="{{ thread.profilePic }}" />
          </div>

          <div class="message-container">
            <div class="message-data">
              <span class="name">{{thread.creator}}</span>
              <span class="date">{{thread.time}}</span>
            </div>

            <div [ngSwitch]="edit && thread.creatorId == currentUser.id && editingThreadMessage === thread.id">
              <div class="message"
                [ngClass]="{'my-message-content': thread.creatorId == currentUser.id, 'message-content': thread.creatorId != currentUser.id, 'display-none': thread.content == '' && thread.mentions.length == 0,}"
                *ngSwitchCase="false">
                <a (click)="openProfileDialog(messagemention['id'])" *ngFor="let messagemention of thread.mentions">
                  @{{messagemention['name']}}</a>
                <span class="word-break">{{thread.content}}</span>
              </div>

              <div *ngFor="let messagefile of thread.files">
                <img style="max-height: 100px; margin-top: 10px;" *ngIf="this.authService.isImage(messagefile)"
                  src="{{messagefile}}" alt="Angehängtes Bild">
                <div class="pdf" *ngIf="this.authService.isPDF(messagefile)">
                  <img src="assets/img/icons/pdf.svg" alt="">
                  <a href="{{messagefile}}" target="_blank">Show PDF</a>
                </div>
              </div>

              <div *ngIf="thread.creatorId == currentUser.id && editingThreadMessage === thread.id">
                <div *ngSwitchCase="true" class="text-field-container j-flex-end">
                  <textarea (keyup.enter)="updateMessageContent(thread)" #editorThread cols="30"
                    rows="10">{{thread.content}}</textarea>
                  <button class="send-button" mat-icon-button (click)="updateMessageContent(thread)"
                    [disabled]="editorThread.value.length < 1">
                    <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M2.5 19.2812C2.08333 19.4479 1.6875 19.4112 1.3125 19.1712C0.9375 18.9321 0.75 18.5833 0.75 18.125V13.4687C0.75 13.1771 0.833333 12.9167 1 12.6875C1.16667 12.4583 1.39583 12.3125 1.6875 12.25L10.75 9.99999L1.6875 7.74999C1.39583 7.68749 1.16667 7.54166 1 7.31249C0.833333 7.08333 0.75 6.82291 0.75 6.53124V1.87499C0.75 1.41666 0.9375 1.06749 1.3125 0.827493C1.6875 0.588327 2.08333 0.552076 2.5 0.718743L21.75 8.84374C22.2708 9.07291 22.5312 9.45833 22.5312 9.99999C22.5312 10.5417 22.2708 10.9271 21.75 11.1562L2.5 19.2812Z"
                        fill="#444DF2" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>

            <div class="reaction-emoji-container">
              <button class="reaction-emoji-button"
                (click)="firestoreService.addReaction(emoji.key, thread.id, this.currentMessage.id, 'threads')"
                *ngFor="let emoji of thread.reactionCount | keyvalue; let i = index">
                <div class="emoji-fit">{{emoji.key}}</div>
                <span>{{ emoji.value }}</span>
              </button>
            </div>
          </div>
        </div>

        <div class="reaction" [ngClass]="{'own-message': thread.creatorId == currentUser.id}">
          <button mat-icon-button (click)="toggled = true" (click)="this.emojiService.openEmojiPicker(thread, 'threads')">
            <img src="assets/img/icons/add_reaction.svg" alt="">
          </button>
          <button mat-icon-button *ngIf="thread.creatorId == currentUser.id" class="more-menu-button"
            (click)="firestoreService.toggleMoreMenu(thread)">
            <img src="assets/img/icons/more.svg" alt="">
            <div class="more-menu" [class.visible]="thread.messageSelected">
              <span (click)="editThreadMessage(thread)">Edit message</span>
              <span class="delete-message"
                (click)="firestoreService.deleteMessageOfChat('threads', currentMessage.id, thread.id)">Delete
                Message</span>
            </div>
          </button>
        </div>
      </div>
    </section>

  </mat-card-content>

  <div class="user-search-container-textfield thread-search"
    *ngIf="this.userService.openUserContainerThreadTextfield.value == true">
    <button (click)="getUserNameString(user)" class="user-container" *ngFor="let user of this.userService.users">
      <div class="profile-container">
        <img src="{{user.picture}}" alt="profile">
        <div [ngClass]="{'offline-status': user.online === false}" class="online-status"></div>
      </div>
      <span>{{user.name}}</span>
    </button>
  </div>

  <mat-card-content class="textfield-content">
    <div class="text-field-container">
      <textarea (keyup.enter)="addMessageToThread()" [(ngModel)]="message.content" placeholder="Comment..."></textarea>

      <div class="message-buttons">
        <div class="attachments">
          <div class="border-right">
            <button class="mat-icon-button" type="submit"> <label class="custom-file-upload">
                <input class="dataInput" (change)="onFileSelected($event)" type="file"
                  accept="image/jpeg, image/png, application/pdf">
                <img style="z-index: 100;" src="assets/img/icons/add.svg" alt="" />
              </label>
            </button>
          </div>

          <button (click)="this.emojiService.openEmojiPicker('', 'threads')" mat-icon-button>
            <img src="assets/img/icons/smiley.svg" alt="" />
          </button>

          <button (click)="this.userService.openUserContainerThreadTextField()" mat-icon-button>
            <img src="assets/img/icons/@.svg" alt="" />
          </button>
          <ng-container *ngFor="let messagefile of this.message.files">
            <img *ngIf="this.authService.isImage(messagefile) && this.showUploadedFile" src="assets/img/icons/photo.svg"
              alt="Angehängtes Bild">
            <img *ngIf="this.authService.isPDF(messagefile) && this.showUploadedFile" src="assets/img/icons/pdf.svg"
              alt="">
          </ng-container>
        </div>

        <button [disabled]="!message.content && !showUploadedFile" (click)="addMessageToThread()" class="send-button"
          mat-icon-button>
          <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2.5 19.2812C2.08333 19.4479 1.6875 19.4112 1.3125 19.1712C0.9375 18.9321 0.75 18.5833 0.75 18.125V13.4687C0.75 13.1771 0.833333 12.9167 1 12.6875C1.16667 12.4583 1.39583 12.3125 1.6875 12.25L10.75 9.99999L1.6875 7.74999C1.39583 7.68749 1.16667 7.54166 1 7.31249C0.833333 7.08333 0.75 6.82291 0.75 6.53124V1.87499C0.75 1.41666 0.9375 1.06749 1.3125 0.827493C1.6875 0.588327 2.08333 0.552076 2.5 0.718743L21.75 8.84374C22.2708 9.07291 22.5312 9.45833 22.5312 9.99999C22.5312 10.5417 22.2708 10.9271 21.75 11.1562L2.5 19.2812Z"
              fill="#444DF2" />
          </svg>
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>