<mat-card [ngSwitch]="editState">
    <mat-spinner *ngIf="firestoreService.showSpinner == true" class="mat-spinner"></mat-spinner>
    <mat-card-content *ngSwitchCase="false">
        <div class="header">
            <h1>Profile</h1>
            <button mat-icon-button (click)="dialogRef.close()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div class="picture">
            <img src="{{user.picture}}" alt="" />
        </div>
        <div class="name">
            <h1>{{user.name}}</h1>
            <span (click)="editState = true"
                *ngIf="currentUser.id == user.id && !userService.currentUser.name?.startsWith('Guest') && userService.currentUser.picture !== 'assets/img/icons/google.png'">Edit</span>
        </div>
        <div [ngSwitch]="user.online">
            <div class="status" *ngSwitchCase="false">
                <div class="status-circle" style="background-color: grey !important;"></div>
                <span style="color: grey;">Inactive</span>
            </div>
            <div class="status" *ngSwitchCase="true">
                <div class="status-circle" style="background-color: #92c83e !important;"></div>
                <span style="color: #92c83e;">Active</span>
            </div>
        </div>
        <div *ngIf="!userService.currentUser.name?.startsWith('Guest')" class="email-container">
            <div class="mail-icon mr-16">
                <img src="assets/img/icons/mail.svg" alt="">
            </div>
            <div class="email">
                <h2>E-Mail Adress</h2>
                <a matTooltip="send mail" href="mailto:{{user.email}}">{{user.email}}</a>
            </div>
        </div>
        <div *ngIf="userService.currentUser.name?.startsWith('Guest')" class="email-container">
            <div class="mail-icon mr-16">
                <img src="assets/img/icons/mail.svg" alt="">
            </div>
            <div class="email">
                <h2>Guest</h2>
            </div>
        </div>
        <div class="message-button-container">
            <button *ngIf="currentUser.id !== user.id" (click)="selectUser(user)" mat-button
                class="main-button primary mr-16 align-center" type="submit">
                <span class="align-center">
                    <img src="assets/img/icons/message.svg" alt="">
                    Message
                </span>
            </button>
        </div>
    </mat-card-content>

    <mat-card-content *ngSwitchCase="true">
        <div class="header">
            <h1>Edit your profile</h1>
            <button mat-icon-button (click)="dialogRef.close()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div class="picture">
            <label>
                <input class="dataInput" (change)="onFileSelected($event)" type="file" accept="image/jpeg, image/png">
                <img matTooltip="Change Picture" class="custom-file-upload" src="{{user.picture}}" alt="" />
            </label>
        </div>

        <form #editForm="ngForm">
            <div class="display-flex">
                <img class="mr-16" src="assets/img/icons/account_circle_big.svg">
                <h2>Full Name</h2>
            </div>
            <input [(ngModel)]="editedUser.name" #nameInput="ngModel" required name="editedUserName" type="text">
            <mat-error *ngIf="nameInput.invalid">Please enter your name</mat-error>
            <div *ngIf="!userService.currentUser.name?.startsWith('Guest')" class="email-container">
                <div class="mail-icon mr-16">
                    <img src="assets/img/icons/mail.svg" alt="">
                </div>
                <div class="email">
                    <h2>E-Mail Adress</h2>
                    <a matTooltip="send mail" href="mailto:{{user.email}}">{{user.email}}</a>
                </div>
            </div>
        </form>

        <div class="buttons w100">
            <button mat-button class="main-button mr-16" (click)="editState = false">
                Cancel
            </button>
            <button [disabled]="!editForm.valid" (click)="editUser()" class="main-button primary" mat-button>
                Save
            </button>
        </div>
    </mat-card-content>
</mat-card>