<mat-card>
  <mat-card-content>
    <div *ngIf="!chatservice.isMobile" class="headline">
      <img class="workspace-logo" src="assets/img/icons/workspace-logo.svg" alt="workspace-logo" />
      <h2>Code learning</h2>
      <button mat-icon-button (click)="startNewMessage()">
        <img src="assets/img/icons/edit_square.svg" alt="edit" />
      </button>
    </div>

    <div class="mobile-container">
      <mat-accordion>
        <mat-expansion-panel class="mr-mobile" (opened)="panelOpenState = true" (closed)="panelOpenState = false"
          [togglePosition]="'before'">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                <path
                  d="M480-520q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm0-80q33 0 56.5-23.5T560-680q0-33-23.5-56.5T480-760q-33 0-56.5 23.5T400-680q0 33 23.5 56.5T480-600ZM280-120q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm0-80q33 0 56.5-23.5T360-280q0-33-23.5-56.5T280-360q-33 0-56.5 23.5T200-280q0 33 23.5 56.5T280-200Zm400 80q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm0-80q33 0 56.5-23.5T760-280q0-33-23.5-56.5T680-360q-33 0-56.5 23.5T600-280q0 33 23.5 56.5T680-200ZM480-680ZM280-280Zm400 0Z" />
              </svg>Channels
              <button mat-icon-button class="add-channel-btn" (click)="openAddChannelDialog($event)">
              </button>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="channel-section">
            <div class="channel-container">
              <div class="channel-button-container" *ngFor="let channel of this.chatservice.yourChannels;">
                <button (click)="renderChannel(channel)" mat-button>
                  <div class="channel-btn">
                    <span class="channel-name"><b>#</b> {{channel.name}}</span>
                  </div>
                </button>
              </div>
            </div>
            <button (click)="openDialog()" class="add_channel_button">
              <div class="add_channel_button-container">
                <svg xmlns="http://www.w3.org/2000/svg" height="15" viewBox="0 -960 960 960" width="15">
                  <path
                    d="M440-280h80v-160h160v-80H520v-160h-80v160H280v80h160v160Zm40 200q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                </svg>
                <span>Add Channel</span>
              </div>
            </button>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div class="mobile-container">
      <mat-accordion>
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
          [togglePosition]="'before'">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 -960 960 960" width="16">
                <path
                  d="M234-276q51-39 114-61.5T480-360q69 0 132 22.5T726-276q35-41 54.5-93T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 59 19.5 111t54.5 93Zm246-164q-59 0-99.5-40.5T340-580q0-59 40.5-99.5T480-720q59 0 99.5 40.5T620-580q0 59-40.5 99.5T480-440Zm0 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q53 0 100-15.5t86-44.5q-39-29-86-44.5T480-280q-53 0-100 15.5T294-220q39 29 86 44.5T480-160Zm0-360q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm0-60Zm0 360Z" />
              </svg>Direct messages
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="direct-message-container">
            <div *ngFor="let directMessage of personalDirectMessages" class="profile-button-div">
              <button class="direct-message-button br-32" (click)="openDirectMessage(directMessage)">
                <div class="profile-message-container">
                  <img class="d-flex" src="{{ (getOtherUser(directMessage.members))?.picture }}" alt="avatar">
                  <div [ngClass]="{'offline-status': !getOtherUser(directMessage.members)?.online}"
                    class="online-status"></div>
                </div>
                <h3 class="chat-name">{{ getOtherUser(directMessage.members)?.name }} <span
                    *ngIf="getOtherUser(directMessage.members)?.id == currentUser.id">(You)</span></h3>
              </button>
              <button *ngIf="getOtherUser(directMessage.members)?.id !== currentUser.id" matTooltip="Delete chat"
                class="delete-direct-button" mat-icon-button (click)="deleteDirectMessageChat(directMessage.id)">
                <img src="assets/img/icons/close.svg" alt="">
              </button>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

  </mat-card-content>
  <button class="new-message" *ngIf="chatservice.isMobile" mat-fab color="primary" (click)="renderNewMainChat()">
    <mat-icon>edit_square</mat-icon>
  </button>
</mat-card>