<div class="main-container">
    <mat-card>
        <button class="arrow" mat-icon-button [routerLink]="['/']">
            <mat-icon>arrow_back</mat-icon>
        </button>
        <mat-card-content class="impress">
            <header>
                <h1>Impressum</h1>
            </header>
            <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
            <p>Elijah Degen<br />
                78467 Konstanz</p>
            <h2>Kontakt</h2>
            <p>E-Mail: elidegen@gmail.com <br>
                Weitere Daten auf Anfrage</p>
            <p>Quelle: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a></p>
        </mat-card-content>
    </mat-card>
</div>