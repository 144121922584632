<mat-card>
    <mat-card-header class="w100">
        <header class="w100">
            <div class="headline">
                <img src="assets/img/icons/tag-black.svg" alt="tag">
                <h3>{{ currentChat?.name || '' }}</h3>
            </div>
            <button *ngIf="!chatService.isMobile" mat-icon-button (click)="dialogRef?.close()">
                <mat-icon>close</mat-icon>
            </button>
            <button *ngIf="chatService.isMobile" mat-icon-button (click)="backToChat()">
                <mat-icon>close</mat-icon>
            </button>
        </header>
    </mat-card-header>

    <mat-card-content class="w100">
        <div class="channel-info w100" [ngClass]="{'view-state': !editName}" [ngSwitch]="editName">
            <div class="top-row w100">
                <h1>Channel Name</h1>
                <div *ngIf="currentUser.name == currentChat?.creator">
                    <span (click)="editName = true" *ngSwitchCase="false">Edit</span>
                    <span (click)="editChannel()" *ngSwitchCase="true">Save</span>
                </div>
            </div>

            <h3 *ngSwitchCase="false"><img src="assets/img/icons/tag-black.svg" alt="">
                <h3>{{ currentChat?.name || '' }}</h3>
            </h3>
            <input #newName *ngSwitchCase="true" placeholder="Entwicklerteam" class="edit-name w100" type="text"
                value="{{ currentChat?.name || '' }}">
        </div>
    </mat-card-content>
    <mat-card-content class="w100">
        <div class="channel-info w100" [ngClass]="{'view-state': !editDescription}" [ngSwitch]="editDescription">
            <div class="top-row w100">
                <h1>Description</h1>
                <div *ngIf="currentUser.name == currentChat?.creator">
                    <span (click)="editDescription = true" *ngSwitchCase="false">Edit</span>
                    <span (click)="editChannel()" *ngSwitchCase="true">Save</span>
                </div>
            </div>

            <h3 *ngSwitchCase="false">{{ currentChat?.description || '' }}</h3>
            <textarea #newDescription *ngSwitchCase="true" placeholder="Describe your channel" class="w100"
                type="text">{{ currentChat?.description || '' }}</textarea>
            <mat-divider></mat-divider>

            <h1>Created by</h1>
            <span class="creator">{{currentChat?.creator}}</span>
        </div>
    </mat-card-content>
    <mat-card-content *ngIf="chatService.isMobile">
        <app-dialog-show-group-member></app-dialog-show-group-member>
    </mat-card-content>
    <mat-card-content class="button-position">
        <button mat-button class="main-button delete-button" (click)="deleteCurrentUserFromChannel()">
            Leave Channel
        </button>
    </mat-card-content>
</mat-card>